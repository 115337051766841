import axios from "@/plugins/SimpleAxios";

function setCRSFCookie() {
  axios
    .get(`${process.env.VUE_APP_BACK_END_URL}/sanctum/csrf-cookie`)
    .then()
    .catch(() => {
      alert(
        "Tuvimos un problema para cargar la información necesaria para la aplicación."
      );
    });
}

function login(authData) {
  return axios.post(`${process.env.VUE_APP_API_URL}/login`, authData);
}

function register(registerData) {
  return axios.post(`${process.env.VUE_APP_API_URL}/register`, registerData);
}

function resetPassword(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}/reset-password`, data);
}

function logOut() {
  return axios.post(`${process.env.VUE_APP_API_URL}/logout`);
}

function forgotPassword(email) {
  return axios.post(`${process.env.VUE_APP_API_URL}/forgot-password`, email);
}

function userIsAuthenticated() {
  return localStorage.getItem("logged") || false;
}

function user() {
  return axios.get(`${process.env.VUE_APP_API_URL}/user`);
}

function setAuthUser(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

function authUser() {
  return JSON.parse(localStorage.getItem("user"));
}

function authUserIsAdmin() {
  const user = authUser();
  return !!user.roles.find((role) => role.name === "administrador");
}

export {
  setCRSFCookie,
  login,
  register,
  logOut,
  forgotPassword,
  resetPassword,
  userIsAuthenticated,
  user,
  setAuthUser,
  authUser,
  authUserIsAdmin,
};
