<template>
  <c-403-error />
  <div v-if="authenticated" id="nav">
    <div class="container d-flex justify-content-between align-items-center">
      <router-link to="/">
        <img src="/img/logo.png" alt="Logo" width="50" />
      </router-link>
      <div class="d-flex align-items-center">
        <nav v-if="authUserIsAdmin()">
          <ul class="list-unstyled m-0">
            <li class="d-inline-block">
              <router-link
                to="/admin/marcos-legales-generales"
                class="text-decoration-none"
                >MLG</router-link
              >
            </li>
            <li class="d-inline-block">
              <router-link to="/admin/dofs" class="text-decoration-none"
                >DOF's</router-link
              >
            </li>
            <li class="d-inline-block">
              <router-link
                to="/admin/publicaciones-estatales/"
                class="text-decoration-none"
                >Publicaciones estatales</router-link
              >
            </li>
            <li class="d-inline-block">
              <router-link
                to="/admin/publicaciones-municipales/"
                class="text-decoration-none"
                >Publicaciones municipales</router-link
              >
            </li>
            <li class="d-inline-block">
              <router-link to="/admin/usuarios/" class="text-decoration-none"
                >Usuarios</router-link
              >
            </li>
            <li class="d-inline-block">
              <router-link
                to="/admin/herramientas/"
                class="text-decoration-none"
                >Herramientas</router-link
              >
            </li>
            <li class="nav-item dropdown d-inline-block">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Configuración
              </a>
              <ul
                class="dropdown-menu border-0"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <router-link
                    to="/admin/configuraciones/aspectos/"
                    class="text-decoration-none dropdown-item"
                    >Aspecto</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/configuraciones/autoridades-regulatorias/"
                    class="text-decoration-none dropdown-item"
                    >Autoridad regulatoria</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/configuraciones/categorias/"
                    class="text-decoration-none dropdown-item"
                    >Categoría</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/dependencias/"
                    class="text-decoration-none dropdown-item"
                    >Dependencias</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/tipos-de-documentos/"
                    class="text-decoration-none dropdown-item"
                    >Documentos</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/configuraciones/estados/"
                    class="text-decoration-none dropdown-item"
                    >Estados</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/configuraciones/frecuencias/"
                    class="text-decoration-none dropdown-item"
                    >Frecuencia</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/configuraciones/giros/"
                    class="text-decoration-none dropdown-item"
                    >Giros</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/configuraciones/ordenamientos"
                    class="text-decoration-none dropdown-item"
                    >Ordenamiento</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/configuraciones/perfiles/"
                    class="text-decoration-none dropdown-item"
                    >Perfiles</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/configuraciones/semaforos/"
                    class="text-decoration-none dropdown-item"
                    >Semáforo</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/configuraciones/tipos-de-verificacion/"
                    class="text-decoration-none dropdown-item"
                    >Tipo de verificación</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/configuraciones/publicaciones-tipos/"
                    class="text-decoration-none dropdown-item"
                    >Tipos de publicaciones</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <nav v-if="!authUserIsAdmin()">
          <ul class="list-unstyled m-0">
            <li class="d-inline-block">
              <router-link to="/biblioteca-legal" class="text-decoration-none"
                >Biblioteca legal</router-link
              >
            </li>
            <li class="d-inline-block">
              <router-link
                to="/diario-oficial-de-la-federacion"
                class="text-decoration-none"
                >DOF</router-link
              >
            </li>
            <li class="d-inline-block">
              <router-link
                to="/publicaciones-estatales"
                class="text-decoration-none"
                >Publicaciones estatales</router-link
              >
            </li>
            <li class="d-inline-block">
              <router-link
                to="/publicaciones-municipales"
                class="text-decoration-none"
                >Publicaciones municipales</router-link
              >
            </li>
            <li class="d-inline-block">
              <router-link to="/matriz-legal" class="text-decoration-none"
                >Mi matriz</router-link
              >
            </li>
            <li class="d-inline-block">
              <router-link to="/herramientas" class="text-decoration-none"
                >Herramientas</router-link
              >
            </li>
          </ul>
        </nav>
        <div class="dropdown">
          <button class="nav-menu c-bg-primary small" data-bs-toggle="dropdown">
            EP
          </button>
          <ul class="dropdown-menu border-0 shadow-sm">
            <li v-if="!authUserIsAdmin()">
              <router-link class="dropdown-item small" to="/perfil"
                >Perfil</router-link
              >
            </li>
            <li v-if="!authUserIsAdmin()">
              <router-link class="dropdown-item small" to="/suscripcion"
                >Suscripción</router-link
              >
            </li>
            <li>
              <a
                @click="logOut"
                href="javascript:void(0)"
                class="dropdown-item small"
              >
                Cerrar sesión
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr class="mt-0" />
  </div>
  <router-view />
</template>
<script>
import { logOut, authUser, authUserIsAdmin } from "@/services/auth";
import C403Error from "@/components/C403Error";

export default {
  components: {
    C403Error,
  },
  data() {
    return {
      authUser: {},
      authenticated: localStorage.getItem("logged"),
    };
  },
  mounted() {
    this.authUser = authUser();
  },
  methods: {
    async logOut() {
      this.loading = true;
      try {
        await logOut();
        localStorage.removeItem("logged");
        localStorage.removeItem("user");
        localStorage.removeItem("hasPlan");
        window.location.href = "/auth/login";
      } catch (error) {
        alert("No pudimos cerrar tu sesión, inténtalo nuevamente.");
        this.loading = false;
      }
    },
    authUserIsAdmin() {
      return authUserIsAdmin();
    },
  },
};
</script>
<style lang="sass" scoped>
#nav
  padding: 0.4rem
  .nav-menu
    border-radius: 50%
    width: 35px
    height: 35px
    border: 0
    color: white
  a
    color: #333333
    display: inline-block
    padding: 0 10px
hr
  background-color: #d0d0d0
</style>
