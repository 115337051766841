export default [
  {
    path: "/admin/tipos-de-documentos",
    name: "DocumentTypesList",
    component: () =>
      import(
        /* webpackChunkName: "document-types" */ "../views/admin/document_types/List"
      ),
  },
  {
    path: "/admin/tipos-de-documentos/crear",
    name: "DocumentTypesCreate",
    component: () =>
      import(
        /* webpackChunkName: "document-types-create" */ "../views/admin/document_types/Create"
      ),
  },
  {
    path: "/admin/tipos-de-documentos/:id/editar",
    name: "DocumentTypesEdit",
    component: () =>
      import(
        /* webpackChunkName: "document-types-edit" */ "../views/admin/document_types/Edit"
      ),
  },
];
