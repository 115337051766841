export default [
  {
    path: "/admin/dependencias",
    name: "DependenciesList",
    component: () =>
      import(
        /* webpackChunkName: "dependencies" */ "../views/admin/dependencies/List"
      ),
  },
  {
    path: "/admin/dependencias/crear",
    name: "DependenciesCreate",
    component: () =>
      import(
        /* webpackChunkName: "dependencies-create" */ "../views/admin/dependencies/Create"
      ),
  },
  {
    path: "/admin/dependencias/:id/editar",
    name: "DependenciesEdit",
    component: () =>
      import(
        /* webpackChunkName: "dependencies-edit" */ "../views/admin/dependencies/Edit"
      ),
  },
];
