<template>
  <div
    id="notification"
    class="
      toast
      align-items-center
      text-white
      border-0
      position-fixed
      top-0
      end-0
      p-3
      me-3
      mt-3
    "
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="d-flex">
      <div class="toast-body">
        <span id="notification-text"></span>
      </div>
      <button
        type="button"
        class="btn-close btn-close-white me-2 m-auto"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.toast
  z-index: 9999
</style>
