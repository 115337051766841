const baseURL = "/admin/configuraciones/tipos-de-verificacion";
export default [
  {
    path: baseURL,
    name: "VerificacionesList",
    component: () =>
      import(
        /* webpackChunkName: "verificaciones" */ "../views/admin/settings/verificaciones/List"
      ),
  },
  {
    path: `${baseURL}/crear`,
    name: "VerificacionesCreate",
    component: () =>
      import(
        /* webpackChunkName: "verificaciones-create" */ "../views/admin/settings/verificaciones/Create"
      ),
  },
  {
    path: `${baseURL}/:id/editar`,
    name: "VerificacionesEdit",
    component: () =>
      import(
        /* webpackChunkName: "verificaciones-edit" */ "../views/admin/settings/verificaciones/Edit"
      ),
  },
];
