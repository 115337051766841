const baseURL = "/admin/configuraciones/perfiles";
export default [
  {
    path: baseURL,
    name: "ProfilesList",
    component: () =>
      import(
        /* webpackChunkName: "profiles" */ "../views/admin/settings/profiles/List"
      ),
  },
  {
    path: `${baseURL}/crear`,
    name: "profilesCreate",
    component: () =>
      import(
        /* webpackChunkName: "profiles-create" */ "../views/admin/settings/profiles/Create"
      ),
  },
  {
    path: `${baseURL}/:id/editar`,
    name: "profilesEdit",
    component: () =>
      import(
        /* webpackChunkName: "profiles-edit" */ "../views/admin/settings/profiles/Edit"
      ),
  },
  {
    path: `${baseURL}/:id/marcos-legales-generales`,
    name: "ProfilesMarcosLegalesGenerales",
    component: () =>
      import(
        /* webpackChunkName: "profiles-marcos-legales-generales" */ "../views/admin/settings/profiles/MLEs/List"
      ),
  },
];
