const baseURL = "/admin/configuraciones/estados";
export default [
  {
    path: baseURL,
    name: "StatesList",
    component: () =>
      import(
        /* webpackChunkName: "states" */ "../views/admin/settings/states/List"
      ),
  },
  {
    path: `${baseURL}/crear`,
    name: "statesCreate",
    component: () =>
      import(
        /* webpackChunkName: "states-create" */ "../views/admin/settings/states/Create"
      ),
  },
  {
    path: `${baseURL}/:id/editar`,
    name: "statesEdit",
    component: () =>
      import(
        /* webpackChunkName: "states-edit" */ "../views/admin/settings/states/Edit"
      ),
  },
  {
    path: `${baseURL}/:id/municipios`,
    name: "Cities",
    component: () =>
      import(
        /* webpackChunkName: "municipios" */ "../views/admin/settings/states/municipios/List"
      ),
  },
  {
    path: `${baseURL}/:id/municipios/crear`,
    name: "CitiesCreate",
    component: () =>
      import(
        /* webpackChunkName: "municipios-create" */ "../views/admin/settings/states/municipios/Create"
      ),
  },
  {
    path: `${baseURL}/:id/municipios/:cityId/editar`,
    name: "CitiesEdit",
    component: () =>
      import(
        /* webpackChunkName: "municipios-edit" */ "../views/admin/settings/states/municipios/Edit"
      ),
  },
];
