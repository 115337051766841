export default [
  {
    path: "/admin/marcos-legales-generales/:id/versiones/:versionId/marcos-legales-especificos",
    name: "MarcosLegalesEspecificosList",
    component: () =>
      import(
        /* webpackChunkName: "marcos-legales-especificos-list" */ "../views/admin/marcos_legales_generales/versions/marcos_legales_especificos/List"
      ),
  },
  {
    path: "/admin/marcos-legales-generales/:id/versiones/:versionId/marcos-legales-especificos/crear",
    name: "MarcosLegalesEspecificosCreate",
    component: () =>
      import(
        /* webpackChunkName: "marcos-legales-especificos-create" */ "../views/admin/marcos_legales_generales/versions/marcos_legales_especificos/Create"
      ),
  },
  {
    path: "/admin/marcos-legales-generales/:id/versiones",
    name: "MarcosLegalesGeneralesVersionesList",
    component: () =>
      import(
        /* webpackChunkName: "marcos-legales-generales-versiones-list" */ "../views/admin/marcos_legales_generales/versions/List"
      ),
  },
  {
    path: "/admin/marcos-legales-generales/:id/versiones/:versionId/marcos-legales-especificos/:mleId/editar",
    name: "MarcosLegalesEspecificosEdit",
    component: () =>
      import(
        /* webpackChunkName: "marcos-legales-especificos-edit" */ "../views/admin/marcos_legales_generales/versions/marcos_legales_especificos/Edit"
      ),
  },
  {
    path: "/admin/marcos-legales-generales/:id/versiones/:versionId/marcos-legales-especificos/:mleId/herramientas",
    name: "MarcosLegalesEspecificosHerramientasList",
    component: () =>
      import(
        /* webpackChunkName: "marcos-legales-especificos-herramientas-list" */ "../views/admin/marcos_legales_generales/versions/marcos_legales_especificos/tools/List"
      ),
  },
  {
    path: "/admin/marcos-legales-generales/:id/versiones/:versionId/marcos-legales-especificos/:mleId/herramientas/crear",
    name: "MarcosLegalesEspecificosHerramientasCreate",
    component: () =>
      import(
        /* webpackChunkName: "marcos-legales-especificos-herramientas-create" */ "../views/admin/marcos_legales_generales/versions/marcos_legales_especificos/tools/Create"
      ),
  },
  {
    path: "/admin/marcos-legales-generales/:id/versiones/:versionId/marcos-legales-especificos/:mleId/herramientas/:toolId/editar",
    name: "MarcosLegalesEspecificosHerramientasEdit",
    component: () =>
      import(
        /* webpackChunkName: "marcos-legales-especificos-herramientas-edit" */ "../views/admin/marcos_legales_generales/versions/marcos_legales_especificos/tools/Edit"
      ),
  },
];
