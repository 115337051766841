export default [
  {
    path: "/admin/herramientas/",
    name: "HerramientasList",
    component: () =>
      import(
        /* webpackChunkName: "herramientas-list" */ "../views/admin/herramientas/List"
      ),
  },
  {
    path: "/admin/marcos-legales-generales/:id/versiones/:versionId/marcos-legales-especificos/:mleId/herramientas/:toolId/archivos",
    name: "MarcosLegalesEspecificosHerramientasFiles",
    component: () =>
      import(
        /* webpackChunkName: "marcos-legales-especificos-herramientas-edit" */ "../views/admin/marcos_legales_generales/versions/marcos_legales_especificos/tools/files/List"
      ),
  },
  {
    path: "/admin/marcos-legales-generales/:id/versiones/:versionId/marcos-legales-especificos/:mleId/herramientas/:toolId/imagenes",
    name: "MarcosLegalesEspecificosHerramientasImages",
    component: () =>
      import(
        /* webpackChunkName: "marcos-legales-especificos-herramientas-edit" */ "../views/admin/marcos_legales_generales/versions/marcos_legales_especificos/tools/images/List"
      ),
  },
];
