const baseURL = "/admin/configuraciones/semaforos";
export default [
  {
    path: baseURL,
    name: "SemaforosList",
    component: () =>
      import(
        /* webpackChunkName: "semaforos" */ "../views/admin/settings/semaforos/List"
      ),
  },
  {
    path: `${baseURL}/crear`,
    name: "SemaforosCreate",
    component: () =>
      import(
        /* webpackChunkName: "semaforos-create" */ "../views/admin/settings/semaforos/Create"
      ),
  },
  {
    path: `${baseURL}/:id/editar`,
    name: "SemaforosEdit",
    component: () =>
      import(
        /* webpackChunkName: "semaforos-edit" */ "../views/admin/settings/semaforos/Edit"
      ),
  },
];
