const baseURL = "/admin/configuraciones/categorias";
export default [
  {
    path: baseURL,
    name: "CategoriesList",
    component: () =>
      import(
        /* webpackChunkName: "categories" */ "../views/admin/settings/categories/List"
      ),
  },
  {
    path: `${baseURL}/crear`,
    name: "CategoriesCreate",
    component: () =>
      import(
        /* webpackChunkName: "categories-create" */ "../views/admin/settings/categories/Create"
      ),
  },
  {
    path: `${baseURL}/:id/editar`,
    name: "CategoriesEdit",
    component: () =>
      import(
        /* webpackChunkName: "categories-edit" */ "../views/admin/settings/categories/Edit"
      ),
  },
];
