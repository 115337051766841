const baseURL = "/admin/configuraciones/publicaciones-tipos";
export default [
  {
    path: baseURL,
    name: "PublicacionesTiposList",
    component: () =>
      import(
        /* webpackChunkName: "publicaciones-tipos" */ "../views/admin/settings/publicaciones_tipos/List"
      ),
  },
  {
    path: `${baseURL}/crear`,
    name: "PublicacionesTiposCreate",
    component: () =>
      import(
        /* webpackChunkName: "publicaciones-tipos-create" */ "../views/admin/settings/publicaciones_tipos/Create"
      ),
  },
  {
    path: `${baseURL}/:id/editar`,
    name: "PublicacionesTiposEdit",
    component: () =>
      import(
        /* webpackChunkName: "publicaciones-tipos-edit" */ "../views/admin/settings/publicaciones_tipos/Edit"
      ),
  },
];
