export default [
  {
    path: "/admin/publicaciones-estatales/",
    name: "PublicacionesEstatales",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/publicaciones_estatales/List"
      ),
  },
  {
    path: "/admin/publicaciones-estatales/crear",
    name: "PublicacionEstatalCrear",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/publicaciones_estatales/Create"
      ),
  },
  {
    path: "/admin/publicaciones-estatales/:id/editar",
    name: "PublicacionEstatalEditar",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/publicaciones_estatales/Edit"
      ),
  },
  {
    path: "/admin/publicaciones-municipales/",
    name: "PublicacionesMunicipales",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/publicaciones_muncipales/List"
      ),
  },
  {
    path: "/admin/publicaciones-municipales/crear",
    name: "PublicacionMunicipalCrear",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/publicaciones_muncipales/Create"
      ),
  },
  {
    path: "/admin/publicaciones-municipales/:id/editar",
    name: "PublicacionMunicipalEditar",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/publicaciones_muncipales/Edit"
      ),
  },
];
