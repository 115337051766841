const baseURL = "/admin/configuraciones/frecuencias";
export default [
  {
    path: baseURL,
    name: "FrequenciesList",
    component: () =>
      import(
        /* webpackChunkName: "frequencies" */ "../views/admin/settings/frecuencias/List"
      ),
  },
  {
    path: `${baseURL}/crear`,
    name: "FrequenciesCreate",
    component: () =>
      import(
        /* webpackChunkName: "frequencies-create" */ "../views/admin/settings/frecuencias/Create"
      ),
  },
  {
    path: `${baseURL}/:id/editar`,
    name: "FrequenciesEdit",
    component: () =>
      import(
        /* webpackChunkName: "frequencies-edit" */ "../views/admin/settings/frecuencias/Edit"
      ),
  },
];
