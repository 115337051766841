<template>
  <div class="container-404 d-flex justify-content-center align-items-center">
    <h4>Página no encontrada</h4>
  </div>
</template>

<script>
export default {
  name: "C404Page",
};
</script>

<style scoped>
.container-404 {
  min-height: 100vh;
}
</style>
