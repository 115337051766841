const baseURL = "/admin/configuraciones/giros";
export default [
  {
    path: baseURL,
    name: "GirosList",
    component: () =>
      import(
        /* webpackChunkName: "giros" */ "../views/admin/settings/giros/List"
      ),
  },
  {
    path: `${baseURL}/crear`,
    name: "GirosCreate",
    component: () =>
      import(
        /* webpackChunkName: "giros-create" */ "../views/admin/settings/giros/Create"
      ),
  },
  {
    path: `${baseURL}/:id/editar`,
    name: "GirosEdit",
    component: () =>
      import(
        /* webpackChunkName: "giros-edit" */ "../views/admin/settings/giros/Edit"
      ),
  },
];
