const baseURL = "/admin/configuraciones/aspectos";
export default [
  {
    path: baseURL,
    name: "AspectosList",
    component: () =>
      import(
        /* webpackChunkName: "aspectos" */ "../views/admin/settings/aspectos/List"
      ),
  },
  {
    path: `${baseURL}/crear`,
    name: "AspectosCreate",
    component: () =>
      import(
        /* webpackChunkName: "aspectos-create" */ "../views/admin/settings/aspectos/Create"
      ),
  },
  {
    path: `${baseURL}/:id/editar`,
    name: "AspectosEdit",
    component: () =>
      import(
        /* webpackChunkName: "aspectos-edit" */ "../views/admin/settings/aspectos/Edit"
      ),
  },
];
