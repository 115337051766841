const baseURL = "/admin/configuraciones/ordenamientos";
export default [
  {
    path: baseURL,
    name: "OrdenamientosList",
    component: () =>
      import(
        /* webpackChunkName: "ordenamientos" */ "../views/admin/settings/ordenamientos/List"
      ),
  },
  {
    path: `${baseURL}/crear`,
    name: "OrdenamientosCreate",
    component: () =>
      import(
        /* webpackChunkName: "ordenamientos-create" */ "../views/admin/settings/ordenamientos/Create"
      ),
  },
  {
    path: `${baseURL}/:id/editar`,
    name: "OrdenamientosEdit",
    component: () =>
      import(
        /* webpackChunkName: "ordenamientos-edit" */ "../views/admin/settings/ordenamientos/Edit"
      ),
  },
];
