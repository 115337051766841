<template>
  <div v-if="loader" class="text-center mt-5">
    <c-loader></c-loader>
  </div>
  <div v-else class="container mt-5">
    <!--<plans :userCurrentPlan="plan" />-->
  </div>
</template>

<script>
// import { subscriptions } from "@/services/users";
// import Plans from "@/components/Plans";
import CLoader from "@/components/CLoader";
import { authUser } from "@/services/auth";
// import { showNotification } from "@/services/notification";

export default {
  name: "Home",
  components: {
    // Plans,
    CLoader,
  },
  data() {
    return {
      loader: true,
      loading: false,
      // plan: {},
    };
  },
  mounted() {
    // this.getSubscriptions();
    this.initialRedirect();
  },
  methods: {
    initialRedirect() {
      if (!authUser().city) {
        this.$router.push("/ayuda-matriz");
      }
    },
    /* getSubscriptions() {
      subscriptions()
        .then((response) => {
          if (response.data.name) {
            this.plan = response.data;
          }
          this.loader = false;
        })
        .catch(() => {
          showNotification(400, "error", "No pudimos cargar tu plan");
        });
    },*/
  },
};
</script>
