const baseURL = "/admin/configuraciones/autoridades-regulatorias";
export default [
  {
    path: baseURL,
    name: "AutoridadesList",
    component: () =>
      import(
        /* webpackChunkName: "autoridades" */ "../views/admin/settings/autoridades_regulatorias/List"
      ),
  },
  {
    path: `${baseURL}/crear`,
    name: "AutoridadesCreate",
    component: () =>
      import(
        /* webpackChunkName: "autoridades-create" */ "../views/admin/settings/autoridades_regulatorias/Create"
      ),
  },
  {
    path: `${baseURL}/:id/editar`,
    name: "AutoridadesEdit",
    component: () =>
      import(
        /* webpackChunkName: "autoridades-edit" */ "../views/admin/settings/autoridades_regulatorias/Edit"
      ),
  },
];
