export default [
  {
    path: "/admin/marcos-legales-generales/crear",
    name: "MarcosLegalesGeneralesCreate",
    component: () =>
      import(
        /* webpackChunkName: "marcos-legales-generales" */ "../views/admin/marcos_legales_generales/Create"
      ),
  },
  {
    path: "/admin/marcos-legales-generales/",
    name: "MarcosLegalesGeneralesList",
    component: () =>
      import(
        /* webpackChunkName: "marcos-legales-generales-list" */ "../views/admin/marcos_legales_generales/List"
      ),
  },
  {
    path: "/admin/marcos-legales-generales/:id/editar",
    name: "MarcosLegalesGeneralesEdit",
    component: () =>
      import(
        /* webpackChunkName: "marcos-legales-generales-edit" */ "../views/admin/marcos_legales_generales/Edit"
      ),
  },
  {
    path: "/admin/marcos-legales-generales/:id/versiones/crear",
    name: "MarcosLegalesGeneralesVersionesCreate",
    component: () =>
      import(
        /* webpackChunkName: "marcos-legales-generales-versiones-create" */ "../views/admin/marcos_legales_generales/versions/Create"
      ),
  },
  {
    path: "/admin/marcos-legales-generales/:id/versiones/:versionId/editar",
    name: "MarcosLegalesGeneralesVersionesEdit",
    component: () =>
      import(
        /* webpackChunkName: "marcos-legales-generales-versiones-edit" */ "../views/admin/marcos_legales_generales/versions/Edit"
      ),
  },
];
