export default [
  {
    path: "/admin/usuarios/",
    name: "UsersList",
    component: () =>
      import(/* webpackChunkName: "users-list" */ "../views/admin/users/List"),
  },
  {
    path: "/admin/usuarios/crear",
    name: "UsersCreate",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/users/Create"),
  },
  {
    path: "/admin/usuarios/:id/editar",
    name: "UsersEdit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/users/Edit.vue"),
  },
];
