import { createRouter, createWebHistory } from "vue-router";
import AdminMLGRoutes from "./MLGs";
import AdminPublicacionesRoutes from "./publicaciones";
import AdminMarcosLeGalesEspecificos from "./marcos_legales_especificos";
import AdminUsers from "./users";
import Tools from "./tools";
import Dependencies from "./dependencies";
import DocumentTypes from "./document_types";
import Categories from "./categories";
import Aspectos from "./aspectos";
import Ordenamientos from "./ordenamientos";
import Autoridades from "./autoridades_regulatorias";
import Verificaciones from "./verificaciones";
import Semaforos from "./semaforos";
import PublicacionesTipos from "./publicaciones_tipos";
import Frequencies from "./frequencies";
import Giros from "./giros";
import States from "./states";
import Profiles from "./profiles";
import Home from "../views/Home.vue";
import C404Page from "../components/C404Page";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // meta: { requiresAuth: true },
  },
  {
    path: "/ayuda-matriz",
    name: "AyudaMatriz",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AyudaMatriz"),
    // meta: { requiresAuth: true },
  },
  {
    path: "/perfil",
    name: "Profile",
    component: () => import(/* webpackChunkName: "about" */ "../views/Profile"),
    // meta: { requiresAuth: true },
  },
  {
    path: "/suscripcion",
    name: "Subscription",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Subscription"),
    // meta: { requiresAuth: true },
  },
  {
    path: "/payment/confirmation",
    name: "payment-confirmation",
    component: () =>
      import(
        /* webpackChunkName: "payment-confirmation" */ "../views/payment/Confirmation"
      ),
    // meta: { requiresAuth: true },
  },
  {
    path: "/auth/login",
    name: "AuthLogin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/Login"),
  },
  {
    path: "/auth/registro",
    name: "AuthRegister",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/Register"),
  },
  {
    path: "/auth/forgot-password",
    name: "AuthForgotPassword",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/ForgotPassword"),
  },
  {
    path: "/password-reset/:token",
    name: "AuthResetPassword",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/ResetPassword"),
  },
  ...AdminMLGRoutes,
  ...AdminPublicacionesRoutes,
  ...AdminMarcosLeGalesEspecificos,
  ...AdminUsers,
  ...Tools,
  ...Dependencies,
  ...DocumentTypes,
  ...Categories,
  ...Aspectos,
  ...Ordenamientos,
  ...Autoridades,
  ...Verificaciones,
  ...Semaforos,
  ...PublicacionesTipos,
  ...Frequencies,
  ...Giros,
  ...States,
  ...Profiles,
  {
    path: "/admin/dofs/crear",
    name: "newDofs",
    component: () =>
      import(
        /* webpackChunkName: "admin-dofs-create" */ "../views/admin/dofs/Create"
      ),
  },
  {
    path: "/admin/dofs",
    name: "listDofs",
    component: () =>
      import(
        /* webpackChunkName: "admin-dofs-list" */ "../views/admin/dofs/List"
      ),
  },
  {
    path: "/admin/dofs/:id",
    name: "detallesDofs",
    component: () =>
      import(
        /* webpackChunkName: "admin-dofs-details" */ "../views/admin/dofs/Detail"
      ),
  },
  {
    path: "/biblioteca-legal",
    name: "BibliotecaLegal",
    component: () =>
      import(/* webpackChunkName: "biblioteca" */ "../views/BibliotecaLegal"),
  },
  {
    path: "/diario-oficial-de-la-federacion",
    name: "diario-oficial",
    component: () =>
      import(
        /* webpackChunkName: "diario-oficial" */ "../views/DiarioOficialDeLaFederacion/List"
      ),
  },
  /*{
    path: "/diario-oficial-de-la-federacion/:id",
    name: "diario-oficial-detail",
    component: () =>
      import(
        /!* webpackChunkName: "diario-oficial-detail" *!/ "../views/DiarioOficialDeLaFederacion/Detail"
      ),
  },*/
  {
    path: "/publicaciones-estatales",
    name: "publicaciones-estatales",
    component: () =>
      import(
        /* webpackChunkName: "publicaciones-estatales" */ "../views/publicacionesEstatales/List"
      ),
  },
  /*{
    path: "/publicaciones-estatales/:id",
    name: "publicaciones-estatales-detail",
    component: () =>
      import(
        /!* webpackChunkName: "publicaciones-estatales-detail" *!/ "../views/publicacionesEstatales/Detail"
      ),
  },*/
  {
    path: "/publicaciones-municipales",
    name: "publicaciones-municipales",
    component: () =>
      import(
        /* webpackChunkName: "publicaciones-municipales" */ "../views/publicacionesMunicipales/List"
      ),
  },
  /*{
    path: "/publicaciones-municipales/:id",
    name: "publicaciones-municipales-detail",
    component: () =>
      import(
        /!* webpackChunkName: "publicaciones-municipales-detail" *!/ "../views/publicacionesMunicipales/Detail"
      ),
  },*/
  {
    path: "/matriz-legal",
    name: "MatrizLegal",
    component: () =>
      import(/* webpackChunkName: "matriz-legal" */ "../views/MatrizLegal"),
  },
  {
    path: "/herramientas",
    name: "Herramientas",
    component: () =>
      import(/* webpackChunkName: "herrammientas" */ "../views/Herramientas"),
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    name: "404",
    component: C404Page,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const logged = localStorage.getItem("logged");

  localStorage.setItem("hasPlan", "true"); // temporal fix
  const hasPlan = localStorage.getItem("hasPlan");

  const user = JSON.parse(localStorage.getItem("user"));
  const openRoutesNames = [
    "AuthLogin",
    "AuthRegister",
    "AuthResetPassword",
    "AuthForgotPassword",
  ];
  const routeName = to.name;
  /*
    - si está logeado
      - si es admin
        - entra a la ruta que quiera
      - si no es admin
        - si tiene plan
          - si es ruta de autenticación mandamos a home
          - si es ruta de app dejamos pasar
        - si no tiene plan:
          - sin importar la ruta mandamos a vista para pagar
    - si no está logeado
      - si son rutas de autenticación dejamos pasar
      - si son rutas de la app mandamos a login
   */
  if (logged) {
    if (user.roles.length && user.roles[0].name === "administrador") {
      next();
    } else {
      if (hasPlan) {
        openRoutesNames.includes(routeName) ? next({ name: "Home" }) : next();
      } else {
        routeName === "Subscription" ? next() : next({ name: "Subscription" });
      }
    }
  } else {
    if (openRoutesNames.includes(routeName)) {
      next();
    } else {
      next({ name: "AuthLogin" });
    }
  }
});

export default router;
